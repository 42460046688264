import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import TopicCard from 'components/TopicCard'
import { IntlProvider } from 'contexts/intl/IntlContext'

const queryImage = graphql`
  query {
    allMarkdownRemark(sort: { frontmatter: { publishedAt: DESC } }) {
      edges {
        node {
          frontmatter {
            topics
          }
        }
      }
    }
  }
`

const Topics = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          isBlogPost={false}
          lang={locale}
          title={formatMessage({ id: 'topics.title' })}
          description={formatMessage({ id: 'meta.description' })}
          keywords={formatMessage({ id: 'meta.keywords' })}
          url={formatMessage({ id: 'meta.url.blog' })}
        />
        <section className='section is-size-4-desktop is-size-5-touch'>
          <h1 className='title has-text-centered has-text-light'>
            <FormattedMessage id='topics.title' />
          </h1>
          <div className='container'>
            <hr />
            <div className='columns'>
              <div className='column is-half is-offset-one-quarter'>
                <StaticQuery
                  query={queryImage}
                  render={data => {
                    if (data.errors) {
                      throw data.errors
                    }
                    const posts = data.allMarkdownRemark.edges.map(({ node }) => node)
                    const postsLocale = posts
                    var topicsArray = []
                    postsLocale.forEach(post => {
                      post.frontmatter.topics.forEach(topic => {
                        if (topicsArray.includes(topic) == false) {
                          topicsArray.push(topic)
                        }
                      })
                    })
                    topicsArray = topicsArray.sort()
                    return (
                      <>
                        {topicsArray.map(topic => {
                          const arrayTopic = topic.split('-')
                          for (var i = 0; i < arrayTopic.length; i++) {
                            arrayTopic[i] = arrayTopic[i].charAt(0).toUpperCase() + arrayTopic[i].slice(1)
                          }
                          const customTopic = arrayTopic.join(' ')
                          return <TopicCard title={customTopic} link={topic} />
                        })}
                      </>
                    )
                  }}
                />
              </div>
            </div>
            <p className='has-text-centered'>
              <FormattedMessage id='checkOut' />{' '}
              <Link to={getPath('tools', locale)}>
                <FormattedMessage id='tools.title' />
              </Link>
              {'.'}
            </p>
          </div>
        </section>
      </Layout>
    )}
  </IntlProvider>
)

Topics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Topics
